import {Component} from '@angular/core';

@Component({
    selector: 'app-loading-spinner-fullscreen',
    templateUrl: './loading-spinner-fullscreen.component.html',
    styleUrls: ['./loading-spinner-fullscreen.component.scss']
})
export class LoadingSpinnerFullscreenComponent {

    constructor() {
    }

}
