import {Component, Input, OnChanges, OnDestroy, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ILibraryItemFiltersData, Library, LibraryItemFilters, ProjectDetails, ProjectLibraryItem} from '../../models';
import {ProjectLibraryItemDefault, ProjectLibraryItemStatus} from '../../models/project/project-libary-item';
import {ObjectUtil} from '../../utils';
import {EffectCategory, quantificationCategories} from '../../enums/effect-category/effect-category';
import {ActivatedRoute} from '@angular/router';
import {LibraryType} from '../../enums/library-type/library-type';
import {IProjectLibraryItemData} from '../../models/project/project-data';
import {QuantifySupplementProjectLibraryItemModalComponent} from '../quantify-supplement-project-library-item-modal/quantify-supplement-project-library-item-modal.component';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {PdfService} from '../../services';
import {Subscription} from 'rxjs';
import {FormGroup} from '@ng-stack/forms';

@Component({
    selector: 'app-pdf-page-risks-and-opportunities-content',
    templateUrl: './pdf-page-risks-and-opportunities-content.component.html',
    styleUrls: ['./pdf-page-risks-and-opportunities-content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PdfPageRisksAndOpportunitiesContentComponent implements OnChanges, OnDestroy {

    public cssClasses = ['pdf-page-a3-landscape'];
    @Input()
    public forPdf = false;
    @Input()
    public clientOnly = false;
    @Input()
    public options: string[];
    @Input()
    public projectDetails: ProjectDetails;

    @Input() set filters(libraryItemFilters: LibraryItemFilters) {
        this.libraryItemFilters = libraryItemFilters;
        this.filterControls = this.filters.getControl();

        this.unsubscribeFilterControlsSubscription();
        this.filterControlsSubscriptions = this.filterControls.valueChanges.subscribe((value) => {
            this.updateFilteredItems(value);
        });

        if (this.forPdf && this.options.includes(PdfService.FILTERED_ONLY)) {
            this.updateFilteredItems(this.filterControls.getRawValue());
        }
    }

    get filters(): LibraryItemFilters {
        return this.libraryItemFilters;
    }

    public filterControls: FormGroup<ILibraryItemFiltersData>;
    public filtersValue: ILibraryItemFiltersData;

    public columnAmount = 14;
    public sortFields = {
        bestGuess: 0,
        score: -1
    };
    public allItemsDisabled = false;
    public allItemsSelected = false;

    public projectLibraryCategories: Array<{ title: string; items: ProjectLibraryItem[]; none: string }>;
    public maxRemarksVisible = 3;
    public emptyQuantificationItem: ProjectLibraryItem;
    public quantificationCategoriesRisks: { key: EffectCategory; label: string; effectCategory: string; fieldName: string }[];

    private libraryItemFilters: LibraryItemFilters;
    private filterControlsSubscriptions: Subscription;

    constructor(
        private route: ActivatedRoute,
        private modalService: VwuiModalService,
        private translateService: TranslateService
    ) {
        this.columnAmount = this.forPdf ? 14 : 15;
    }

    ngOnChanges(): void {
        this.initFields();
    }

    ngOnDestroy(): void {
        this.unsubscribeFilterControlsSubscription();
    }

    public toggleSort(sortType: string): void {
        Object.keys(this.sortFields).forEach((key) => {
            if (['bestGuess', 'score'].includes(key)) {
                if (key !== sortType) {
                    this.sortFields[key] = 0;
                } else {
                    this.sortFields[sortType] = this.sortFields[sortType] - 1;
                    if (this.sortFields[sortType] === -2) {
                        this.sortFields[sortType] = 1;
                    }
                }
            }
        });

        this.initFields();
    }

    private initFields(): void {
        if (!this.projectDetails) {
            return;
        }
        this.quantificationCategoriesRisks = this.projectDetails.projectInfo.quantificationCategoriesRisks
            .filter((item) =>
                quantificationCategories.find((quantificationItem) => quantificationItem.key === item)
            )
            .map((item) => {
                const category = quantificationCategories.find((quantificationItem) => quantificationItem.key === item);
                return {...category, label: this.translateService.instant(`PAGE_RISK_RAINBOW.tables.columns.${category.key}`)};
            });

        const allProjectItems = [...this.projectDetails.getRisksWithinFilters(this.filtersValue), ...this.projectDetails.getOpportunitiesWithinFilters(this.filtersValue)]
            .filter((item) =>
                item.status !== ProjectLibraryItemStatus.SETTLED
            )
            .filter((item) => this.shouldShowItemBasedOnPdfOptions(item))
            .filter((item) => !this.clientOnly || (item.riskClient && item.status !== ProjectLibraryItemStatus.PROPOSED))
            .sort((a, b) => {
                const fallbackSort = (aname, bname) => {
                    const fullTitleA = (aname.status === ProjectLibraryItemStatus.PROPOSED ? 'A' : 'B') + aname.getTitle(this.projectDetails.projectLibraryItems);
                    const fullTitleB = (bname.status === ProjectLibraryItemStatus.PROPOSED ? 'A' : 'B') + bname.getTitle(this.projectDetails.projectLibraryItems);

                    return fullTitleA.localeCompare(fullTitleB);
                };

                if (this.sortFields.bestGuess === 0 && this.sortFields.score === 0) {
                    return fallbackSort(a, b);
                } else {
                    if (this.sortFields.score !== 0) {
                        if (a.getScore() === b.getScore()) {
                            return fallbackSort(a, b);
                        }

                        return Math.sign(a.getScore() - b.getScore()) * this.sortFields.score;
                    } else {
                        if (a.bestGuessCosts === b.bestGuessCosts) {
                            return fallbackSort(a, b);
                        }

                        return Math.sign(a.bestGuessCosts - b.bestGuessCosts) * this.sortFields.bestGuess;
                    }
                }
            });

        this.projectLibraryCategories = [{
            title: this.translateService.instant('PAGE_RISK_RAINBOW.tables.titles.risks'),
            items: allProjectItems.filter((item) =>
                item.libraryType === LibraryType.RISKS
            ),
            none: this.translateService.instant('PAGE_RISK_RAINBOW.tables.titles.risks_none')
        }];

        if (!this.clientOnly) {
            this.projectLibraryCategories.push({
                title: this.translateService.instant('PAGE_RISK_RAINBOW.tables.titles.opportunities'),
                items: allProjectItems.filter((item) =>
                    item.libraryType === LibraryType.OPPORTUNITIES
                ),
                none: this.translateService.instant('PAGE_RISK_RAINBOW.tables.titles.opportunities_none')
            });
        }

        const emptyQuantification = ObjectUtil.mergeObjects([ProjectLibraryItemDefault, {
            effectCategoryMoney: true,
            effectCategoryTime: true,
            effectCategoryQuality: true,
            effectCategorySafety: true,
            effectCategorySurroundings: true,
            effectCategoryImage: true,
            effectCategoryReputation: true
        }]);
        this.emptyQuantificationItem = new ProjectLibraryItem(emptyQuantification as IProjectLibraryItemData);

        this.recalculateAllItemsSelected();
    }

    public selectAllItems($event: CustomEvent) {
        Object.values(this.filterControls.controls.selectedItems.controls).forEach((control) => {
            control.patchValue({selected: $event.detail.checked});
        });

        this.filtersValue = this.filterControls.getRawValue();
        this.updateFilteredItems(this.filtersValue);
    }

    private recalculateAllItemsSelected() {
        if (this.forPdf) {
            return;
        }

        const allItems = this.projectLibraryCategories.map((projectLibraryCategory) => projectLibraryCategory.items).flat();

        this.allItemsDisabled = allItems.length === 0;
        this.allItemsSelected = !this.allItemsDisabled && !allItems.find((item) => !this.filterControls.controls.selectedItems.get(`item-${item.id}`)?.getRawValue().selected);
    }

    public openModal(item: ProjectLibraryItem, tab?: string, event?: Event): void {
        if (event) {
            event.stopImmediatePropagation();
        }
        const modal = this.modalService.open(
            QuantifySupplementProjectLibraryItemModalComponent,
            {
                closeOnBackdropClick: false,
                modalClass: 'large-modal',
                data: {
                    projectLibraryItem: item,
                    projectDetails: this.projectDetails,
                    tab
                }
            }
        );

        modal.afterClosed.subscribe((data: { action: string; item: ProjectLibraryItem; items?: ProjectLibraryItem[] }) => {
            if (data) {
                switch (data.action) {
                    case 'submit':
                        Object.assign(item, data.item);
                        break;
                    case 'duplicated':
                        this.projectDetails.projectLibraryItems = data.items;
                        this.filters.composeSelectedItemControls(data.items.map((dataItem) => dataItem.id));
                        this.initFields();
                        break;
                    case 'deleted':
                        this.projectDetails.projectLibraryItems = this.projectDetails.projectLibraryItems
                            .filter((projectItem) => item.id !== projectItem.id);
                        this.filters.removeSelectedItemControls([item.id]);
                        this.initFields();
                        break;
                }
            }
        });
    }

    private updateFilteredItems(val: ILibraryItemFiltersData) {
        this.filtersValue = val;
        this.initFields();
    }

    private shouldShowItemBasedOnPdfOptions(item: ProjectLibraryItem): boolean {
        if (!this.forPdf) {
            return true;
        }

        if (!!this.options && this.options.includes(PdfService.INCLUDE_PROPOSALS)) {
            return true;
        } else {
            return item.status !== ProjectLibraryItemStatus.PROPOSED;
        }
    }

    private unsubscribeFilterControlsSubscription(): void {
        if (this.filterControlsSubscriptions) {
            this.filterControlsSubscriptions.unsubscribe();
        }
    }

}
